import React, { useState } from 'react';
import Button from '../elements/Button';
import { Link } from 'react-router-dom';

const HeroSlider = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
  };

  return (
    <div className="hero-slider">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={index === currentSlide ? 'slide active' : 'slide'}
          style={{ backgroundImage: `url(${slide.image})` }}
        >
          <div className="content">
            <h2>{slide.title}</h2>
            <p style={{textAlign:"center"}}>{slide.description}</p>
            {/* <Button color="primary" href={"https://w.com"} wideMobile={true} >Sign up</Button> */}
            {slide.btnText}
            {/* {
                slide.btnText != null ?
                <a href={slide.btnLink}>
                    <Button color="secondary " wideMobile={true}>{slide.btnText}</Button>
                    </a>
                
                :
                <>
                </>
            } */}
          </div>
        </div>
      ))}
      <button className="prev" onClick={prevSlide}>
        &#10094;
      </button>
      <button className="next" onClick={nextSlide}>
        &#10095;
      </button>
    </div>
  );
};

export default HeroSlider;
